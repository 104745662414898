import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(({ breakpoints, palette, spacing, shape }) => ({
  form: {
    [breakpoints.down("md")]: {
      padding: spacing(10, 4),
    },
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: 750,
    margin: "0 auto",
    padding: spacing(10, 8),
    "& > *": {
      margin: spacing(2, 0),
    },
  },
  input: {
    ...shape,
  },
}))

const defaultProps = {
  variant: "filled",
  color: "secondary",
  fullWidth: true,
}

const ContactPage = () => {
  const classes = useStyles()
  return (
    <Layout>
      <SEO title="Contact" />
      <form
        id="PBA-contact-formulier"
        action="https://formsubmit.io/send/photographybyaudrey@hotmail.com"
        method="POST"
        className={classes.form}
      >
        <Typography color="secondary" variant="h4" align="center">
          Contact formulier
        </Typography>
        <input
          name="_redirect"
          type="hidden"
          id="name"
          value="https://photographybyaudrey.nl/bedankt"
        />

        <TextField
          id="name"
          name="naam"
          label="Uw naam"
          type="text"
          required
          {...defaultProps}
          className={classes.input}
        />
        <TextField
          id="email"
          name="email"
          label="Uw e-mail"
          type="email"
          required
          {...defaultProps}
          className={classes.input}
        />
        <TextField
          id="number"
          name="nummer"
          label="Uw telefoonnummer"
          type="tel"
          {...defaultProps}
          className={classes.input}
        />
        <TextField
          id="message"
          name="bericht"
          label="Uw bericht"
          multiline
          rows={6}
          required
          {...defaultProps}
          className={classes.input}
        />

        <input name="_formsubmit_id" type="text" style={{ display: "none" }} />

        <Button
          type="submit"
          color="secondary"
          variant="contained"
          size="large"
        >
          Verzenden
        </Button>
      </form>
    </Layout>
  )
}

export default ContactPage
